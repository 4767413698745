import { ApplyAddons, UseStatefull, } from "../index.js";
export const Component = (props = {}) => {
    const state = UseStatefull({
        elements: [],
    });
    return [
        [
            ApplyAddons({
                type: props.element ? props.element : "div",
                state: state,
            }, props.addons ? props.addons : []),
        ],
        (data) => (state.elements = props.generate
            ? props.generate(data)
            : data),
        state,
    ];
};
