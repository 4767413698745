// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credits-screen {
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  .default-button {
    max-width: 400px;
  }
  .credit-content {
    margin: 0;
    overflow-y: scroll;
    width: 100%;
    padding: 0;
    max-width: 600px;
    display: flex;

    flex-direction: column;
    .credit-title {
      color: var(--font-color);
      text-align: center;
      font-family: var(--font-family);

      font-size: var(--font-size-xlarge);
    }
    .credit-name {
      color: var(--font-color);
      text-align: center;
      font-family: var(--font-family);

      font-size: var(--font-size-large);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/website/Screens/Credits/CreditsScreen.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;;EAEtB,mBAAmB;EACnB;IACE,gBAAgB;EAClB;EACA;IACE,SAAS;IACT,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,gBAAgB;IAChB,aAAa;;IAEb,sBAAsB;IACtB;MACE,wBAAwB;MACxB,kBAAkB;MAClB,+BAA+B;;MAE/B,kCAAkC;IACpC;IACA;MACE,wBAAwB;MACxB,kBAAkB;MAClB,+BAA+B;;MAE/B,iCAAiC;IACnC;EACF;AACF","sourcesContent":[".credits-screen {\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  align-items: center;\r\n  .default-button {\r\n    max-width: 400px;\r\n  }\r\n  .credit-content {\r\n    margin: 0;\r\n    overflow-y: scroll;\r\n    width: 100%;\r\n    padding: 0;\r\n    max-width: 600px;\r\n    display: flex;\r\n\r\n    flex-direction: column;\r\n    .credit-title {\r\n      color: var(--font-color);\r\n      text-align: center;\r\n      font-family: var(--font-family);\r\n\r\n      font-size: var(--font-size-xlarge);\r\n    }\r\n    .credit-name {\r\n      color: var(--font-color);\r\n      text-align: center;\r\n      font-family: var(--font-family);\r\n\r\n      font-size: var(--font-size-large);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
