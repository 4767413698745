import { UseCascade } from "../Functions/index.js";
import { ApplyAddons, } from "../index.js";
export function cascadeElement(type, update) {
    const funcs = [];
    const updateAll = () => funcs.forEach((_) => _());
    const element = (addons, children, cascadeProps) => {
        const { runCascade, addToElment } = UseCascade(cascadeProps);
        funcs.push(runCascade);
        return [
            ApplyAddons({
                type: type,
                children: children,
                ...addToElment((elm) => {
                    update(elm);
                }),
            }, addons)
        ];
    };
    return [element, updateAll];
}
