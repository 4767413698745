import { element } from "@dvg/elmtree";
export function Button(name, click) {
    return element("button", [
        {
            attrs: {
                className: "default-button",
            },
        },
        {
            events: {
                click,
            },
        },
    ], name);
}
