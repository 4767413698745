// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hom-buttons {
    height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  .default-button {
    max-width: 400px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/website/Screens/Home/HomeScreen.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd,aAAa;EACb,sBAAsB;EACtB,uBAAuB;;EAEvB,mBAAmB;EACnB;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".hom-buttons {\r\n    height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n\r\n  align-items: center;\r\n  .default-button {\r\n    max-width: 400px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
