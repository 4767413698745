import { ElementCreator } from "./ElementCreator.js";
import { Controller } from "./Controler.js";
export const AddCascade = (cascadeProps, update) => {
    return {
        cascade: {
            origin: cascadeProps,
            receiver(elm) {
                update(elm, cascadeProps);
            },
        },
    };
};
export function ApplyAddons(obj, addons) {
    for (const addon of addons) {
        if (addon.attrs) {
            obj.attrs ??= {};
            obj.attrs = { ...obj.attrs, ...addon.attrs };
            continue;
        }
        obj = { ...obj, ...addon };
    }
    return obj;
}
export function UseInputBind(bind) {
    return {
        data: bind,
        bind(to, type) {
            return {
                bindInput: {
                    bindTo: bind,
                    objectPropertyName: to,
                    valueType: type,
                },
            };
        },
    };
}
export function UseCascade(cascadeProps) {
    if (!cascadeProps)
        cascadeProps = {};
    const addToElment = (update) => AddCascade(cascadeProps, update);
    return {
        runCascade: () => {
            return Controller.cascade.run(cascadeProps);
        },
        addToElment,
        props: cascadeProps,
    };
}
export function UseAnimation() { }
export function AddAttributes(data) {
    return {
        attrs: data,
    };
}
export function AddClass(classes) {
    return {
        attrs: {
            className: classes.join(" "),
        },
    };
}
export function AddEvents(data) {
    return {
        events: data,
    };
}
export function AddInputBind(data) {
    return {
        bindInput: data,
    };
}
export function AddStyles(data) {
    return {
        attrs: {
            style: data,
        },
    };
}
export function UseStatefull(state = { elements: [] }) {
    return Controller.stateful.getState(state);
}
export function BloomRoot(tree) {
    ElementCreator.renderElements(tree, document.body);
}
export function BloomBranch(tree, elm) {
    ElementCreator.renderElements(tree, elm);
}
export function DecayRoot() {
    ElementCreator.safetlyRemoveAll();
}
export function DecayBranch(elm) {
    ElementCreator.safetlyRemoveElement(elm);
}
