import { form } from "./Inputs.js";
import { FormBuilderElement } from "./FormBuilderElements.js";
import { UseInputBind } from "../../index.js";
export function GetForm(props) {
    const bindObj = UseInputBind(props.inputBind);
    return form(props.elements.map((element) => {
        if (!element.addons)
            element.addons = [];
        return FormBuilderElement[element.type](element, bindObj);
    }));
}
