import { div, title } from "@dvg/elmtree";
import "./HomeScreen.css";
import { App } from "../../App/App";
import { Button } from "../../Components/Buttont";
const Links = {
    Steam: "https://store.steampowered.com/app/2547740/Crystalline_Bliss/",
    Discord: "https://discord.gg/XaWSsKQauC",
    GitHub: "https://github.com/Divine-Star-Software/CrystallineBliss",
    YouTube: "https://www.youtube.com/channel/UC-02oe0-jCSy5KMqVSuXWbg",
};
const GoTo = (url) => window.open(url, "_blank").focus();
export function HomeScreen() {
    App.screens.screens.add([
        {
            id: "home",
            getElements: () => {
                return div("home-screen", [
                    title("h1", "Crystalline Bliss", "game-title"),
                    div("hom-buttons", [
                        Button("Play Online", () => (window.location.href = "/beta/")),
                        Button("Buy On Steam", () => GoTo(Links.Steam)),
                        Button("Join Discord", () => GoTo(Links.Discord)),
                        Button("GoTo Github", () => GoTo(Links.GitHub)),
                        Button("See On YouTube", () => GoTo(Links.YouTube)),
                        Button("Credits", () => App.screens.update("credits")),
                    ]),
                ]);
            },
        },
    ]);
}
