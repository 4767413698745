import { AddAttributes, AddClass, AddEvents, ApplyAddons, } from "../Functions/index.js";
export const element = (type, addons = [], children = []) => {
    return [
        ApplyAddons({
            type: type,
            children: Array.isArray(children) ? children : [],
            text: typeof children == "string" ? children : undefined,
        }, addons),
    ];
};
export const div = (className, children, addons = []) => {
    return element("div", [AddClass([className]), ...addons], children);
};
export const hr = (className = "", addons = []) => {
    return element("hr", [AddClass([className]), ...addons]);
};
export const title = (titleType, text, className = "", addons = []) => {
    return element(titleType, [AddClass([className]), ...addons], text);
};
export const paragraph = (children, className = "", addons = []) => {
    return element("p", [AddClass([className]), ...addons], children);
};
export const button = (children, onClick, addons = []) => {
    return element("button", [
        AddEvents({
            click: async (event) => {
                await onClick(event);
            },
            keydown: async ({ key }) => {
                if (key == " ") {
                    await onClick();
                }
            },
        }),
        ...addons,
    ], children);
};
export const image = (src, addons = []) => {
    return element("img", [
        AddAttributes({
            image: {
                src: src,
            },
        }),
        ...addons,
    ]);
};
