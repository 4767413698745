import { div, paragraph, title } from "@dvg/elmtree";
import "./CreditsScreen.css";
import { App } from "../../App/App";
import { Button } from "../../Components/Buttont";
export function CreditsScreen() {
    App.screens.screens.add([
        {
            id: "credits",
            getElements: () => {
                return div("credits-screen", [
                    title("h1", "Crystalline Bliss", "game-title"),
                    div("credit-content", [
                        //programming
                        title("h2", "Programming", "credit-title"),
                        title("h3", "Lucas D. Johnson", "credit-name"),
                        //sound
                        title("h2", "Sound Design", "credit-title"),
                        title("h3", "Tyler Quinn", "credit-name"),
                        //music
                        title("h2", "Music & Art", "credit-title"),
                        title("h3", "Lucas D. Johnson", "credit-name"),
                        //Thanks
                        title("h2", "Special Thanks To", "credit-title"),
                        title("h3", "Brad Hall", "credit-name"),
                        //Coyright
                        paragraph("Crystalline Bliss © 2024 Divine Star LLC", "credit-name"),
                        paragraph("Built with Divine Voxel Engine & BabylonJS", "credit-name"),
                    ]),
                    Button("Go Back", () => App.screens.update("home")),
                ]);
            },
        },
    ]);
}
