import { IterableWeakMap } from "../Util/IterableWeakMap.js";
import { ElementCreator } from "./ElementCreator.js";
export const Controller = {
    stateful: {
        map: new WeakMap(),
        register(elmObj, componentElm) {
            if (!elmObj.state) {
                throw new Error("Must have a the component property set.");
            }
            this.map.set(elmObj.state, componentElm);
        },
        getState(state) {
            const foundState = this.map.get(state);
            if (foundState)
                return state;
            const self = this;
            const proxy = new Proxy(state, {
                get(target, prop, receiver) {
                    return Reflect.get(target, prop, receiver);
                },
                set(target, prop, newValue, receiver) {
                    target[prop] = newValue;
                    self.runUpdate(proxy);
                    return true;
                },
            });
            return proxy;
        },
        runUpdate(state) {
            const data = this.map.get(state);
            if (!data)
                return false;
            const elm = data;
            elm.innerHTML = "";
            ElementCreator.renderElements([state.elements], elm);
        },
    },
    cascade: {
        map: new WeakMap(),
        register(elmObj, elm) {
            if (!elmObj.cascade?.origin)
                return;
            if (!this.map.has(elmObj.cascade.origin))
                return this.map.set(elmObj.cascade.origin, new IterableWeakMap([[elm, elmObj.cascade.receiver]]));
            this.map.get(elmObj.cascade.origin).set(elm, elmObj.cascade.receiver);
        },
        run(props) {
            const elements = this.map.get(props);
            if (!elements)
                return false;
            for (const [key, value] of elements) {
                value(key, props);
            }
            return true;
        },
    },
    inputBind: {
        map: new WeakMap(),
        inputFunctions: {
            string: (elm) => {
                if (elm.type == "checkbox")
                    return elm.checked ? "true" : "false";
                return String(elm.value);
            },
            number: (elm) => {
                if (elm.type == "checkbox")
                    return elm.checked ? 1 : 0;
                return Number(elm.value);
            },
            boolean: (elm) => {
                if (elm.type == "checkbox")
                    return elm.checked ? true : false;
                return Boolean(elm.value);
            },
        },
        bind(elm, elmObj) {
            if (!elmObj.bindInput)
                return;
            this.map.set(elm, elmObj.bindInput);
            elm.value =
                elmObj.bindInput.bindTo[elmObj.bindInput.objectPropertyName];
            elm.addEventListener("input", (ev) => {
                const inputData = this.map.get(ev.target);
                if (!inputData)
                    return;
                const valueType = inputData.valueType;
                const newInput = this.inputFunctions[valueType](ev.target);
                inputData.bindTo[inputData.objectPropertyName] = newInput;
            });
        },
    },
};
