import { div, element } from "../BaseElements.js";
import { ApplyAddons, } from "../../index.js";
import { AddAttributes, AddClass, AddEvents, UseCascade, } from "../../Functions/index.js";
export const form = (children) => {
    return [
        {
            type: "form",
            children: children,
        },
    ];
};
export const input = (inputType, label, addons = []) => {
    return div("form-group", [
        div("form-label", [
            {
                type: "label",
                text: label,
            },
        ]),
        div("form-input", [
            ApplyAddons({
                type: "input",
                attrs: {
                    input: {
                        type: inputType,
                    },
                },
            }, addons),
        ]),
    ]);
};
export const textArea = (addons = []) => {
    return div("form-group", [
        ApplyAddons({
            type: "textarea",
        }, addons),
    ]);
};
export const dataInput = (addons = []) => {
    return div("form-group", [
        ApplyAddons({
            type: "textarea",
            attrs: {
                className: "data-display",
                textArea: {
                    rows: 20,
                    cols: 200,
                },
            },
        }, addons),
    ]);
};
const formOption = (option, addons) => {
    let className = ["form-option"];
    if (option.active) {
        className.push("active");
    }
    return element("div", [AddClass(className), ...addons], [element("p", [AddClass(["form-option-text"])], option.text)]);
};
export const radioInput = (options, onChange) => {
    const cascade = UseCascade({ option: "" });
    const elements = [];
    for (const option of options) {
        if (option.active) {
            cascade.props.option = option.value;
        }
        elements.push(formOption(option, [
            AddAttributes({
                tabIndex: 0,
            }),
            cascade.addToElment((elm, props) => {
                if (props.option == option.value) {
                    elm.classList.remove("inactive");
                    elm.classList.add("active");
                }
                else {
                    elm.classList.add("inactive");
                    elm.classList.remove("active");
                }
            }),
            AddEvents({
                click: () => {
                    cascade.props.option = option.value;
                    onChange(option.value);
                    cascade.runCascade();
                },
                keydown: (event) => {
                    if (event.key == " " || event.key == "Enter") {
                        onChange(option.value);
                        event.target.click();
                    }
                },
            }),
        ]));
    }
    return div("vform-optin-group", elements);
};
export const horizontalRadioInput = (options, onChange) => {
    const cascade = UseCascade({ option: "" });
    const elements = [];
    for (const option of options) {
        if (option.active) {
            cascade.props.option = option.value;
        }
        elements.push(formOption(option, [
            cascade.addToElment((elm, props) => {
                if (props.option == option.value) {
                    elm.classList.remove("inactive");
                    elm.classList.add("active");
                }
                else {
                    elm.classList.add("inactive");
                    elm.classList.remove("active");
                }
            }),
            AddEvents({
                click: () => {
                    onChange(option.value);
                },
                keydown: (event) => {
                    if (event.key == " " || event.key == "Enter") {
                        onChange(option.value);
                        event.target.click();
                    }
                },
            }),
        ]));
    }
    return div("hform-optin-group", elements);
};
export const formButton = (text, onClick, addons = []) => {
    return div("form-group", [
        ApplyAddons({
            type: "button",
            attrs: {
                className: "form-buttom",
            },
            text: text,
            events: {
                click: (event) => {
                    event.preventDefault();
                    onClick();
                },
            },
        }, addons),
    ]);
};
export const formText = (text) => {
    return div("form-text", [
        {
            type: "p",
            text: text,
        },
    ]);
};
export const formTitle = (text) => {
    return div("form-title", [
        {
            type: "h1",
            text: text,
        },
    ]);
};
