import { ScreenManager } from "@dvg/core/Screens/ScreenManager.js";
import { App } from "./App";
import { RegisterScreens } from "../Screens/RegisterScreens";
import { BloomRoot, div } from "@dvg/elmtree";
export function InitApp() {
    App.init(new ScreenManager("APP"));
    App.screens.screenModes.add([
        {
            id: "main",
            getElements: (component) => div("main-screen", component),
        },
    ]);
    RegisterScreens();
    BloomRoot(App.screens.render());
    App.screens.update("home", "main");
}
