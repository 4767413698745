import { div, button } from "../BaseElements.js";
import { input, formButton, formTitle, radioInput, dataInput, formText, horizontalRadioInput, } from "./Inputs.js";
export const FormBuilderElement = {
    text: (element) => formText(element.text),
    title: (element) => formTitle(element.text),
    "number-input": (element, bind) => input("number", element.label, [
        bind.bind(element.bindTo, "number"),
        ...(element && element.addons),
    ]),
    "text-input": (element, bind) => input("text", element.label, [
        bind.bind(element.bindTo, "string"),
        ...(element && element.addons),
    ]),
    "text-area-input": (element, bind) => dataInput([
        bind.bind(element.bindTo, "string"),
        ...(element && element.addons),
    ]),
    "radio-input": (element, bind) => [
        div("form-label", [
            {
                type: "label",
                text: element.label,
            },
        ]),
        radioInput(element.data, (value) => {
            bind.data[element.bindTo] = value;
            if (element.onChange) {
                element.onChange(value);
            }
        }),
    ],
    toggle: (element, bind) => [
        div("form-label", [
            {
                type: "label",
                text: element.label,
            },
        ]),
        horizontalRadioInput([
            { text: "Enabled", value: "1", active: true },
            { text: "Disabled", value: "0" },
        ], (value) => {
            bind.data[element.bindTo] = Boolean(Number(value));
        }),
    ],
    button: (element, bind) => formButton(element.text, () => {
        element.onClick(bind);
    }, element.addons),
    "button-group": (element, bind) => div("form-button-group", element.buttons.map((b) => button(b.text, (event) => {
        event.preventDefault();
        b.onClick(bind);
    }, b.addons ? b.addons : []))),
    "@dvg/elmtree-obj": (element) => element.data,
};
